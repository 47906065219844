import React from "react";
import styled from "styled-components";
import PageContainer from "../components/PageContainer";

import contact from "../images/contact.png";
import bookWorm from "../images/bookworm_logo.png";
import Head from "../components/Head";

const ContactContainer = styled.div`
  margin: 0 auto;
  background-image: url("${contact}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;

  min-height: 700px;
  min-width: 360px;

  @media (min-width: 769px) {
    min-height: 600px;
    min-width: 450px;
  }
`;

const NameBox = styled.div`
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  transform: rotate(7.5deg) translate(12px);
  font-weight: bold;
  top: 266px;

  @media (min-width: 769px) {
    top: 266px;
  }
`;

const PartnerBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  overflow: hidden;

  & img {
    margin: 0 auto;
    transform: scale(1.5);
    max-width: 160px;
    margin-left: 10px;
  }

  & span {
    color: white;
    margin-left: -60px;
  }
  position: absolute;
  top: 360px;
  @media (min-width: 769px) {
    top: 360px;
  }
`;

function Contact() {
  return (
    <>
      <Head title="Katherine Tillotson - Contact" />
      <PageContainer reduceBackgroundOpacity>
        <ContactContainer>
          <NameBox>katherinetillotson@att.net</NameBox>
          <PartnerBox>
            <span>Represented by:</span>
            <a href="mailto:rosemary@stimolaliterarystudio.com">
              <img src={bookWorm} />
            </a>
          </PartnerBox>
        </ContactContainer>
      </PageContainer>
    </>
  );
}

export default Contact;
